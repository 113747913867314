import pluralize from '@/utils/pluralize.utils';
import { WebsiteId } from '@/env';
import { CreateOrderTab, CreateOrderTariff } from '@/types/store/createOrder';
import { CdekOffice } from '@/types/dto/cdek-office';
import {
  DEFAULT_CURRENCY_BY_WEBSITE_ID,
  DELIVERY_TYPES,
  PARCEL_TARIFF_ID,
  POSTAMAT,
  PROFI_TARIFF_OFFICE_OFFICE_ID,
  PROFI_TARIFF_OFFICE_POSTAMAT_ID,
} from './constants';

interface IgetTariffParams {
  tariffs: CreateOrderTariff[];
  form: { payer?: string; recipientType: string; senderType: string };
  senderOffice?: CdekOffice | null;
  receiverOffice?: CdekOffice | null;
  tariffProfiEnabled?: boolean;
  preferredTariff?: number | null;
}

// сортировка по стоимости тарифа
export const compare = (a: CreateOrderTariff, b: CreateOrderTariff) => {
  if (a.price < b.price) {
    return -1;
  }
  if (a.price > b.price) {
    return 1;
  }
  return 0;
};

export function getTariff({
  tariffs = [],
  form = {
    senderType: '',
    recipientType: '',
  },
  senderOffice = null,
  receiverOffice = null,
  tariffProfiEnabled = false,
  preferredTariff = null,
}: IgetTariffParams): CreateOrderTariff | undefined {
  let modeFrom = form.senderType;
  let modeTo = form.recipientType;

  if (modeFrom === DELIVERY_TYPES.OFFICE && senderOffice && senderOffice.type === POSTAMAT) {
    modeFrom = DELIVERY_TYPES.POSTAMAT;
  }

  if (modeTo === DELIVERY_TYPES.OFFICE && receiverOffice && receiverOffice.type === POSTAMAT) {
    modeTo = DELIVERY_TYPES.POSTAMAT;
  }

  const type = `${modeFrom}-${modeTo}`;

  const isTariffParcelAvailable = tariffs.some((tariff) => tariff.ec4Id === PARCEL_TARIFF_ID);

  // если пользователь выбрал режим "профи", а выбрать его пользователь может только если тариф профи пришел с бека,
  // см.геттеры isTariffProfiAvailable и isTariffProfiToPostamatAvailable
  if (tariffProfiEnabled) {
    if (modeTo === DELIVERY_TYPES.POSTAMAT) {
      return tariffs.filter((tariff) => tariff.ec4Id === PROFI_TARIFF_OFFICE_POSTAMAT_ID)?.at(0);
    }

    return tariffs.filter((tariff) => tariff.ec4Id === PROFI_TARIFF_OFFICE_OFFICE_ID)?.at(0);
  }

  const foundPreferredTariff =
    preferredTariff &&
    tariffs.find((tariff) => {
      return tariff.ec4Id === preferredTariff && tariff.mode === type;
    });

  const preparedTariffs = tariffs
    .filter((tariff) => {
      if (foundPreferredTariff) {
        return tariff.ec4Id === preferredTariff;
      }

      // если нет посылочки и не выбран режим профи, но тариф профи есть и он дешевле всех тарифов - то выбираем тариф профи
      if (isTariffParcelAvailable) {
        return (
          tariff.ec4Id !== PROFI_TARIFF_OFFICE_OFFICE_ID &&
          tariff.ec4Id !== PROFI_TARIFF_OFFICE_POSTAMAT_ID
        );
      }

      return true;
    })
    .filter((tariff) => tariff.mode === type)
    .sort(compare);

  return preparedTariffs[0];
}

/**
 * @param tariffs {{id: number, mode: string, shortName: string}[]}
 * @param form {{payer: string, recipientType: string,  senderType: string}}
 * @param senderOffice {{type: string}|null}
 * @param receiverOffice {{type: string}|null}
 * @returns {{currentTariff: null|Object, recipientType: string, senderType: string}}
 */
export function findTariffOtherModes(
  tariffs: CreateOrderTariff[],
  form: Record<string, string>,
  senderOffice: CdekOffice | null = null,
  receiverOffice: CdekOffice | null = null,
) {
  const findModes = [
    ['HOME', 'OFFICE'],
    ['OFFICE', 'HOME'],
    ['HOME', 'HOME'],
  ];

  let currentTariff = null;
  let senderType = null;
  let recipientType = null;
  for (let i = 0; i < findModes.length; ++i) {
    const item = findModes[i];
    senderType = item[0];
    recipientType = item[1];
    currentTariff = getTariff({
      tariffs,
      form: { ...form, ...{ senderType, recipientType } },
      senderOffice,
      receiverOffice,
    });

    if (currentTariff) {
      break;
    }
  }

  return {
    senderType,
    recipientType,
    currentTariff,
  };
}

/**
 * Мне нужно найти таб у которого есть тарифф из заранее определенного списка
 * Массивы небольшие. Что-то типа 3 таба в каждом по 4 массива
 * @param tabs
 * @param availableIds
 * @returns {{tab: *, tariffId: number}}
 */
export function findTabWithTariff(tabs: CreateOrderTab[], availableIds: number[]) {
  let tariffId = 0;

  const tab = tabs.find(({ tariffs }) => {
    // Проверяю на id и ec4id, потому что у нас где-то старый калькулятор где-то новый
    // в одном месте так поле называется в другом иначе
    return tariffs.some(({ ec4Id }) => {
      if (availableIds.includes(ec4Id)) {
        tariffId = ec4Id;
        return true;
      }

      return false;
    });
  });

  return {
    tab,
    tariffId,
  };
}

/**
 * @param {Array} tabs
 * @returns {{recipientType: String, tab: Object, tariff: Object, senderType: String}|null}
 */
export function findCourierTariffWithTab(tabs: CreateOrderTab[]) {
  const tabsPlacing = [2, 3];
  const modes = [
    ['HOME', 'HOME'],
    ['HOME', 'OFFICE'],
  ];

  let findTab: CreateOrderTab | undefined;
  let findTariff: CreateOrderTariff | undefined;
  let senderType: string | undefined;
  let recipientType: string | undefined;

  tabsPlacing.some((placing) => {
    findTab = tabs.find((tab) => tab.placing === placing);

    if (!findTab) {
      return false;
    }

    return modes.some((mode) => {
      senderType = mode[0];
      recipientType = mode[1];
      findTariff = getTariff({
        tariffs: findTab?.tariffs || [],
        form: { senderType, recipientType },
      });

      return !!findTariff;
    });
  });

  if (findTariff && findTab) {
    return {
      tab: findTab,
      tariff: findTariff,
      senderType,
      recipientType,
    };
  }

  return null;
}

export function isFirstTab(tab: CreateOrderTab) {
  return tab?.placing === 1;
}

export function isThirdTab(tab: CreateOrderTab) {
  return tab?.placing === 3;
}

interface IVM {
  $notify: (o: Record<string, string | undefined>) => void;
}

export function notify(vm: IVM, text: string | undefined) {
  vm.$notify({
    group: 'notification-error',
    type: 'notification-error',
    title: 'Ошибка',
    text,
  });
}

interface ITextError {
  isAxiosError: boolean;
  response: { data: { message?: string; errors?: string[] } };
}

export function displayError(vm: IVM, e: ITextError) {
  if (!e || !e.isAxiosError) {
    return;
  }
  const { response } = e;
  let text = response.data.message;

  if (!text && response.data.errors) {
    text = response.data.errors[0];
  }

  notify(vm, text);
}

type ObjectType = Record<string, object | string | number | boolean>;

export function removeEmpty(obj: ObjectType) {
  const finalObj: ObjectType = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') {
      const nestedObj = removeEmpty(obj[key] as ObjectType);
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj;
      }
    } else if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key];
    }
  });
  return finalObj;
}

export function getDayTitle(
  minDays: number,
  maxDays: number,
  texts: {
    one: string;
    two: string;
    five: string;
    interval: { one: string; two: string; five: string };
  },
) {
  if (minDays === maxDays) {
    return `${minDays}\xa0${pluralize(minDays, texts.one, texts.two, texts.five)}`;
  }

  return `${minDays}—${maxDays}\xa0${pluralize(
    minDays,
    texts.interval.one,
    texts.interval.two,
    texts.interval.five,
  )}`;
}

export function getDefaultCurrencyByWebsiteId(id: WebsiteId) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return DEFAULT_CURRENCY_BY_WEBSITE_ID[id];
}
